import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import css from "@styled-system/css"
import Sticky from "react-stickynode"

import {
  AccordionGroup,
  Box,
  ColorMode,
  FadeWrapper,
  Flex,
  Heading,
  IconGrid,
  PageHeader,
  RichText,
  SEO,
  Wrapper,
} from "components"

import CollageSlice from "./_collageSlice"
import RichTextSlice from "./_richTextSlice"

function AboutPage() {
  const { page } = useStaticQuery(PAGE_QUERY)

  return (
    <>
      <SEO
        title={page.data.seo_title && page.data.seo_title.text}
        description={page.data.seo_description}
        imageOpenGraph={page.data.seo_image && page.data.seo_image.url}
        imageTwitter={page.data.seo_image && page.data.seo_image.url}
        imageAlt={page.data.seo_image && page.data.seo_image.alt}
      />
      {/* Page header */}
      <PageHeader
        data={{
          heading: page.data.page_subtitle && page.data.page_subtitle.html,
          description:
            page.data.page_description && page.data.page_description.html,
          image: page.data.image,
        }}
      />

      {/* Icon Grid Slice */}
      {page.data.body &&
        page.data.body.length > 0 &&
        page.data.body.map(({ slice_type, primary, items }, index) => {
          switch (slice_type) {
            case "icon_grid":
              return (
                <IconGrid
                  title={primary.title.text}
                  data={{
                    items: items,
                    backgroundColor: primary.background_color,
                  }}
                  key={"slice" + slice_type + index}
                />
              )
            case "rich_text":
              return (
                <RichTextSlice
                  data={{
                    content: primary.richtext && primary.richtext.html,
                    backgroundColor: primary.background_color,
                    buttonLabel: primary.button_name,
                    buttonLink: primary.button_url,
                    width: primary.text_box_width,
                  }}
                  key={"slice" + slice_type + index}
                />
              )
            case "collage":
              return (
                <CollageSlice
                  data={{
                    primaryImage: primary.primary_image,
                    secondaryImage: primary.secondary_image,
                    topBackground:
                      page.data.body[index - 1] &&
                      page.data.body[index - 1].primary &&
                      page.data.body[index - 1].primary.background_color,
                    bottomBackground:
                      page.data.body[index + 1] &&
                      page.data.body[index + 1].primary &&
                      page.data.body[index + 1].primary.background_color,
                  }}
                  key={"slice" + slice_type + index}
                />
              )
            default:
              return null
          }
        })}

      {/* Management bios */}
      <Box as="section" position="relative" mt={["layout.6", "layout.7"]}>
        <Box
          position="absolute"
          top={0}
          left={[0, 32]}
          width={["100%", "calc(100% - 64px)"]}
          height="100%"
          bg="wash"
        />
        <FadeWrapper>
          <Wrapper position="relative" py={["layout.6", "layout.8"]}>
            <Flex
              flexWrap="wrap"
              m={[-16, null, -32]}
              css={css({
                "> *": {
                  width: ["100%", null, null, "50%"],
                  p: [16, null, 32],
                },
              })}
            >
              <Box display={["none", null, "block"]}>
                <Sticky bottomBoundary="#scrollableArea-management">
                  <Box
                    pt={["layout.2", null, null, "layout.4"]}
                    pb={["layout.4", null, null, "layout.6"]}
                    width="100%"
                  >
                    <Heading
                      children={page.data.management_subtitle.text}
                      size={[800, null, null, 700]}
                    />
                  </Box>
                </Sticky>
              </Box>
              <Heading
                children={page.data.management_subtitle.text}
                size={[800, null, null, 700]}
                display={["block", null, "none"]}
              />
              <Box id="scrollableArea-management">
                <AccordionGroup
                  items={page.data.management.map(({ name, title, bio }) => ({
                    id: name,
                    heading: name.text,
                    subheading: title,
                    children: <RichText content={{ html: bio.html }} />,
                  }))}
                />
              </Box>
            </Flex>
          </Wrapper>
        </FadeWrapper>
      </Box>

      {/* Board of Directors bios */}
      <ColorMode mode="dark">
        <Box as="section" position="relative" mt={[0, "layout.4"]}>
          <Box
            position="absolute"
            top={0}
            left={[0, 32]}
            width={["100%", "calc(100% - 64px)"]}
            height="100%"
            bg="background"
          />
          <FadeWrapper>
            <Wrapper position="relative" py={["layout.6", "layout.8"]}>
              <Flex
                flexWrap="wrap"
                m={[-16, null, -32]}
                css={css({
                  "> *": {
                    width: ["100%", null, null, "50%"],
                    p: [16, null, 32],
                  },
                })}
              >
                <Box display={["none", null, "block"]}>
                  <Sticky bottomBoundary="#scrollableArea-board">
                    <Heading
                      children={page.data.board_subtitle.text}
                      size={[800, null, null, 700]}
                      pt={[0, null, null, "layout.4"]}
                      pb={[0, null, null, "layout.6"]}
                    />
                  </Sticky>
                </Box>
                <Heading
                  children={page.data.board_subtitle.text}
                  size={[800, null, null, 700]}
                  display={["block", null, "none"]}
                />
                <Box id="scrollableArea-board">
                  <AccordionGroup
                    items={page.data.board.map(({ name, title, bio }) => ({
                      id: name,
                      heading: name.text,
                      subheading: title,
                      children: <RichText content={{ html: bio.html }} />,
                    }))}
                  />
                </Box>
              </Flex>
            </Wrapper>
          </FadeWrapper>
        </Box>
      </ColorMode>
    </>
  )
}

const PAGE_QUERY = graphql`
  {
    page: prismicAbout {
      data {
        seo_title {
          text
        }
        seo_description
        seo_image {
          url
          alt
        }
        image {
          fluid(maxWidth: 1600) {
            ...GatsbyPrismicImageFluid
          }
          alt
        }
        board {
          bio {
            html
          }
          name {
            text
          }
          title
        }
        page_subtitle {
          html
        }
        page_description {
          html
        }
        board_subtitle {
          text
        }
        management_subtitle {
          text
        }
        body {
          ... on PrismicAboutBodyIconGrid {
            slice_type
            items {
              graphic {
                fluid(maxWidth: 192) {
                  ...GatsbyPrismicImageFluid
                }
                alt
              }
              heading {
                text
              }
              description {
                html
              }
            }
            primary {
              background_color
              title {
                text
              }
            }
          }
          ... on PrismicAboutBodyRichText {
            slice_type
            primary {
              background_color
              text_box_width
              richtext {
                html
              }
              button_name
              button_url {
                url
              }
            }
          }
          ... on PrismicAboutBodyCollage {
            slice_type
            primary {
              primary_image {
                fluid(maxWidth: 1200) {
                  ...GatsbyPrismicImageFluid
                }
              }
              secondary_image {
                fluid(maxWidth: 1200) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
        }
        management {
          bio {
            html
          }
          name {
            text
          }
          title
          headshot {
            fluid(maxWidth: 1200) {
              ...GatsbyPrismicImageFluid
            }
          }
        }
      }
    }
  }
`

export default AboutPage
