import React from "react"

import { Link } from "gatsby"

import {
  Box,
  Button,
  ColorMode,
  FadeWrapper,
  RichText,
  Wrapper,
} from "components"

// A header + paragraph section, to be used throughout the site to deliver
// factoid-style information. Styled as a small block of text, with an image above.

const RichTextSlice = ({ data }) => {
  // Set max width of Rich Text Slice, based on dropdown in Prismic.
  let textBoxWidth = 540

  if (data.width === "100%") {
    textBoxWidth = 1080
  }

  return (
    <ColorMode mode={data.backgroundColor === "Black" ? "dark" : "light"}>
      {/* Thin text box, kept near the left of the screen. */}
      <Box
        className="richText-slice"
        as="section"
        position="relative"
        my={
          !(
            data.backgroundColor === "Gray" || data.backgroundColor === "Black"
          ) && ["layout.6", "layout.8"]
        }
      >
        {/* ~~~~~~~~~~ BACKGROUND ~~~~~~~~~~ */}
        {data.backgroundColor !== "White" && (
          <Box
            position="absolute"
            top={0}
            left={[0, 32]}
            width={["100%", "calc(100% - 64px)"]}
            height="100%"
            bg={data.backgroundColor === "Gray" ? "wash" : "background"}
          />
        )}

        {/* ~~~~~~~~~~ CONTENT ~~~~~~~~~~ */}
        <FadeWrapper>
          <Wrapper
            position="relative"
            py={
              (data.backgroundColor === "Gray" ||
                data.backgroundColor === "Black") && ["layout.6", "layout.8"]
            }
          >
            <Box maxWidth={textBoxWidth}>
              {data.content && (
                <RichText
                  size={500}
                  content={{ html: data.content }}
                  mb={data.buttonLabel && ["layout.2", "layout.4"]}
                  color="text"
                />
              )}
              {data.buttonLabel && data.buttonLink && data.buttonLink.url && (
                <Button
                  children={data.buttonLabel}
                  as={data.buttonLink.url.startsWith("http") ? "a" : Link}
                  to={data.buttonLink.url}
                  href={data.buttonLink.url}
                  target="_blank"
                  rel="noopener noreferrer"
                />
              )}
            </Box>
          </Wrapper>
        </FadeWrapper>
      </Box>
    </ColorMode>
  )
}

export default RichTextSlice
