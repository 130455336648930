import React, { useState, useEffect, useCallback } from "react"
import { useViewportScroll } from "framer-motion"
import Image from "gatsby-image/withIEPolyfill"

import { AspectRatio, Box, ColorMode, Flex, Wrapper } from "components"

function ImageComp({ velocity, image, ratio, index, ...rest }) {
  const { scrollY } = useViewportScroll()
  const [scrollPercentage, setScrollPercentage] = useState(0)
  const [wrapper, setWrapper] = useState(undefined)

  //set wrapper variable to be the measurement div
  const measuredRef = useCallback(node => {
    setWrapper(node)
  }, [])

  //track scroll position-- move percentage from top of screen to bottom
  useEffect(() => {
    if (wrapper) {
      // On IE, force show state immediately to avoid performance issues
      if (
        navigator.userAgent.indexOf("MSIE") !== -1 ||
        navigator.appVersion.indexOf("Trident/") > -1
      ) {
        setScrollPercentage(0.5)
      } else {
        scrollY.onChange(v => {
          const height =
            window.innerHeight ||
            document.documentElement.clientHeight ||
            document.body.clientHeight
          setScrollPercentage(-wrapper.getBoundingClientRect().top / height + 1)
          // setScrollPercentage(v / height)
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrapper])

  return (
    <div ref={measuredRef}>
      <AspectRatio ratio={ratio} overflow="hidden" {...rest}>
        <Box
          position="absolute"
          top="50%"
          left="50%"
          width="100%"
          height={index % 3 === 2 ? "160%" : "150%"}
          css={{ transform: "translate(-50%, -50%)", overflow: "hidden" }}
        >
          <Image
            fluid={image.fluid}
            alt={image.alt}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              transform:
                "translate3d(0, " +
                (10 * -velocity + scrollPercentage * 10 * velocity) +
                "%, 0)",
            }}
            objectFit="cover"
            objectPosition="center"
          />
        </Box>
      </AspectRatio>
    </div>
  )
}

const CollageSlice = ({ data }) => {
  const IMAGE_OFFSET = 64

  return (
    <Box className="collage-slice" as="section" position="relative">
      {/* ~~~~~~~~~~ BACKGROUNDS ~~~~~~~~~~ */}
      {(data.topBackground === "Gray" || data.topBackground === "Black") && (
        <ColorMode mode={data.topBackground === "Black" ? "dark" : "light"}>
          <Box
            position="absolute"
            top={0}
            left={[0, 32]}
            width={["100%", "calc(100% - 64px)"]}
            height="50%"
            bg={data.topBackground === "Gray" ? "wash" : "background"}
          />
        </ColorMode>
      )}
      {(data.bottomBackground === "Gray" ||
        data.bottomBackground === "Black") && (
        <ColorMode mode={data.bottomBackground === "Black" ? "dark" : "light"}>
          <Box
            position="absolute"
            bottom={0}
            left={[0, 32]}
            width={["100%", "calc(100% - 64px)"]}
            height="50%"
            bg={data.bottomBackground === "Gray" ? "wash" : "background"}
          />
        </ColorMode>
      )}

      {/* ~~~~~~~~~~ CONTENT ~~~~~~~~~~ */}
      <Wrapper position="relative" maxWidth={1080 + 64 * 2}>
        <Flex flexWrap="wrap">
          {/* First collage image */}
          <Box width={["100%", "50%"]}>
            <Box mt={[0, IMAGE_OFFSET]} mr={[IMAGE_OFFSET, -IMAGE_OFFSET / 2]}>
              {data.primaryImage && data.primaryImage.fluid && (
                <ImageComp
                  velocity={-1}
                  image={data.primaryImage}
                  ratio={"4:3"}
                />
              )}
            </Box>
          </Box>

          {/* Second collage image */}
          <Box width={["100%", "50%"]}>
            <Box mt={[-IMAGE_OFFSET, 0]} ml={[IMAGE_OFFSET, -IMAGE_OFFSET / 2]}>
              {data.secondaryImage && data.secondaryImage.fluid && (
                <ImageComp
                  velocity={1}
                  image={data.secondaryImage}
                  ratio={"4:3"}
                />
              )}
            </Box>
          </Box>
        </Flex>
      </Wrapper>
    </Box>
  )
}

export default CollageSlice
